
  export default {
    data() {
      return {
        showModal: true,
        link: "https://lin.ee/bkli1RI",
        images: [
          "/images/line-contact.png",
        ],
        currentDesktopImage: "",
        currentMobileImage: "",
        index: 0,
        interval: null,
      };
    },
    methods: {
      startImageRotation() {
        // console.log('..... start image rotation .....')
        this.updateImages();

        // สลับรูปภาพทุกๆ 1 วินาที
        this.interval = setInterval(() => {
          this.index = (this.index + 1) % this.images.length;
          this.updateImages();
        }, 1000);
      },
      updateImages() {
        this.currentDesktopImage = this.images[this.index];
        this.currentMobileImage = this.images[(this.index + 1) % this.images.length];
      },
    },
    mounted() {
      this.startImageRotation();
    },
    beforeDestroy() {
      if (this.interval) {
        clearInterval(this.interval);
      }
    },
    resetModal () {
        this.showModal = false
    }
  };
  